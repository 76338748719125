import PropTypes from "prop-types";
import React from "react";
import ReactVivus from "react-vivus";
import { Link } from "react-router-dom";

const IconBox = ({ data, classOption }) => {
  return (
    <div className={`flex flex-col items-center ${classOption} group`}>
      <div className="flex justify-center items-center mb-4 md:mb-0">
        <ReactVivus
          className="h-12 w-12"
          id={`servicesvg-${data.id}`}
          option={{
            file: data.icon,
            animTimingFunction: "EASE",
            type: "oneByOne",
            delay: 70,
          }}
        />
      </div>
      <Link to={data.link}>
        <div className="text-center md:text-left flex flex-col gap-1 cursor-pointer group-hover:text-primary">
          <h2 className="text-xl font-bold group-hover:text-primary transition delay-250 ">
            {data.title}
          </h2>
          <p className="text-gray-600">{data.desc}</p>
          <div className="border relative">
            <div className="absolute border border-black group-hover:animate-width"></div>
          </div>
        </div>
      </Link>
    </div>
  );
};

IconBox.propTypes = {
  data: PropTypes.object.isRequired,
  classOption: PropTypes.string,
};

IconBox.defaultProps = {
  classOption: "icon-box text-center md:text-left",
};

export default IconBox;

import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logowhite.png";
import FooterData from "../../data/Footer/footerItem.json";
import FooterLinkItem from "../../components/Footer/FooterLinkItem.jsx";

const Footer = () => {
  return (
    <div
      className="footer-section section"
      style={{
        // background:
        // "url(https://www.bizvibez.com/wp-content/uploads/2019/10/asa-1.jpg) no-repeat center/cover",
        backgroundColor: "#042638"
      }}
    >
      <div className="container">
        <div className="row mb-lg-1 mb-md-1 gap-10 mb-6">
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 col-12 mb-6">
            <div className="footer-widget">
              <div className="footer-logo">
                <Link to={process.env.PUBLIC_URL + "/"}>
                  <img 
                  className="w-32 object-cover"
                  src={logo} alt="footer logo" />
                </Link>
              </div>
              <div className="footer-widget-content">
                <div className="content">
                  <p>
                    <a 
                    href="tel:+971 555232006"
                    className="text-white" to={process.env.PUBLIC_URL + "/"}>
                      +971 555232006
                    </a>
                  </p>
                  <p>
                    <a
                    href="mailto:info@nsmarketing.ae"
                    className="text-white" to={process.env.PUBLIC_URL + "/"}>
                      info@nsmarketing.ae
                    </a>{" "}
                  </p>
                </div>
                <div className="footer-social-inline">
                  <a href="#">
                    <i className="fab fa-twitter-square text-white"></i>
                  </a>
                  <a href="#">
                    <i className="fab fa-facebook-square text-white"></i>
                  </a>
                  <a href="#">
                    <i className="fab fa-instagram text-white"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          {FooterData &&
            FooterData.map((single, key) => {
              return (
                <div
                  key={key}
                  className="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-12 mb-6"
                >
                  <FooterLinkItem data={single} key={key} />
                </div>
              );
            })}
     
        </div>

        <div className="row">
          <div className="col">
            <p className="copyright">
              &copy; {new Date().getFullYear()}{" "}
              <span
                style={{
                  color: "#fff",
                }}
              >
                NS Marketing
              </span>
              . All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

import React from "react";
import { Link } from "react-router-dom";

const IntroThree = ({ title, description, image }) => {
  return (
    <div
      className="intro-section section overlay"
      style={{
        backgroundImage: `url(${image})`,
        backgroundPosition: "center center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "100vh",
      }}
    >
      <div className="container">
        <div className="row row-cols-lg-1 row-cols-1">
          <div className="col align-self-center">
            <div className="intro-content-two headline-active text-center mt-xl-8 mt-lg-8 mt-md-8 mt-sm-8 mt-xs-8">
              <h2
                className="title ah-headline"
                style={{
                  fontSize: "3rem",
                  fontWeight: "bold",
                  marginTop: "10rem",
                }}
              >
                {title}
              </h2>
              <div className="desc">
                <p>{description}</p>
              </div>
              <Link
                to={process.env.PUBLIC_URL + "/contact"}
                className="btn btn-primary btn-hover-secondary"
              >
                Get Started Today
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntroThree;

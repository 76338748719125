import React from "react";
import SectionTitle from "../../components/SectionTitles/SectionTitle";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.webp";
import ContactFrom from "../../components/ContactFrom/ContactFrom.jsx";

const ContactFromContainer = () => {
  return (
    <div className="contact-form-section section pt-8 pb-8">
      <Link
        to={"/"}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={logo} alt="logo" className="w-32" />
      </Link>
      <br />
      <div className="container">
        <div className="row">
          <div className="offset-lg-2 col-lg-8">
            <SectionTitle
              headingOption="fz-32"
              title="Let’s talk about your project"
              subTitle="We have made it easy for clients to reach us and get their solutions weaved"
            />
            <ContactFrom />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactFromContainer;

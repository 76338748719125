import React, { useState, useEffect } from 'react';

const ScrollToTop = (props) => {
  const [stick, setStick] = useState(false);
  const onClickHandler = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    let position = window.pageYOffset; // Changed 'var' to 'let' for better scoping

    const scrollHandler = () => {
      const scrollPos = window.pageYOffset;
      if (scrollPos < 200) {
        setStick(false);
      } else if (scrollPos < position) {
        setStick(true);
      } else {
        setStick(false);
      }
      position = scrollPos;
    };

    window.addEventListener('scroll', scrollHandler);
    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, [stick]);

  return (
    <button
      type="button"
      className={`scroll-top ${stick ? 'show' : ''}`}
      onClick={onClickHandler}
      style={{
        marginBottom: '60px',
        position: 'fixed',
        right: '25px',
        bottom: '24px',
        cursor: 'pointer',
        display: stick ? 'block' : 'none',
      }}
      {...props}
    >
      <i className="arrow-top pe-7s-angle-up"></i>
      <i className="arrow-bottom pe-7s-angle-up"></i>
    </button>
  );
};

export default ScrollToTop;

import PropTypes from "prop-types";
import React from "react";
import { motion } from "framer-motion";

const SectionTitle = ({ title, subTitle, titleOption, headingOption }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
      className={`section-title ${titleOption}`}
      data-aos="fade-up"
    >
      <h2
        className={`title ${headingOption}`}
        dangerouslySetInnerHTML={{ __html: title }}
        style={{ fontFamily: "Utopia" }}
      ></h2>
      <p
        style={{
          whiteSpace: "pre-wrap",
          width: "100%",
          textAlign: "center",
          marginTop: "20px",
          padding: "0px 20px",
          maxWidth: "800px",
        }}
      >
        {subTitle}
      </p>
    </div>
  );
};

SectionTitle.propTypes = {
  subTitle: PropTypes.string,
  title: PropTypes.string,
  titleOption: PropTypes.string,
  headingOption: PropTypes.string,
};
SectionTitle.defaultProps = {
  titleOption: "text-center",
  headingOption: "title",
};

export default SectionTitle;
